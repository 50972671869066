 
 const IP_FRONT_SERVER = 'crossoverperu.im-ra.net'
 const URL_RUTA_FRONTEND = `https://${IP_FRONT_SERVER}/#/`

//  const IP_FRONT_SERVER = 'localhost:8080'
//  const URL_RUTA_FRONTEND = `http://${IP_FRONT_SERVER}/#/`

 const ERROR_404 = 404
 const ERROR_505 = 505
 const METHOD_POST = 'POST'
 const METHOD_GET = 'GET'
 const METHOD_DELETE = 'DELETE'
 const METHOD_PUT = 'PUT'
 const METHOD_HEAD = 'HEAD'
 const METHOD_OPTIONS = 'OPTIONS'


 export default {
     URL_RUTA_FRONTEND,
     ERROR_404,
     ERROR_505,
     METHOD_POST,
     METHOD_GET,
     METHOD_DELETE,
     METHOD_PUT,
     METHOD_HEAD,
     METHOD_OPTIONS,
 }